
import { ref, defineComponent } from 'vue'
import * as api from '../../utils/api'
import Menu from '../../components/menu/Admin.vue'
import HospitalData from '../../components/HospitalData.vue'
import { IInvoiceDB } from '../../../interfaces/invoice'
import moment from 'moment-timezone'
import { useDialog } from 'naive-ui'
moment.locale('ja')
moment.tz.setDefault('Asia/Tokyo')

export default defineComponent({
	components: {
		Menu,
		HospitalData,
	},
	data() {
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		return {
			id: '',
			loading: true,
			invoices: [] as IInvoiceDB[],
			useInvoice: [] as number[],
			errorDialog,
		}
	},
	mounted: function () {
		document.title = '請求書管理 - Vets Index'
		this.init()
	},
	methods: {
		init: async function () {
			try {
				this.loading = true
				const fileNames = location.pathname.match(/\/([a-zA-Z0-9-]+)$/)
				if (!fileNames) return false
				const fileName = fileNames[1]
				this.id = fileName
				const data = await api.get(`/v1/admin/invoice/${fileName}`)
				this.invoices = data
				this.loading = false
			} catch (e: any) {
				this.loading = false
				this.errorDialog(e, () => true)
				console.error(e)
			}
		},
		handleUseInvoice: function (value: string[]) {
			// todo
		},
		handlePaidFor: function (t: number) {
			const format = moment(`${t}`, 'YYYYMM').format('YYYY年M月')
			return `${format}利用分`
		},
		handleUnix: function (t: number) {
			const time = new Date(t * 1000)
			return moment(time).format('YYYY年M月D日')
		},
		selectAll: function (target: 'sent' | 'wait' | 'unselect') {
			this.useInvoice = []
			if (target === 'unselect') return
			for (const s of this.invoices) {
				if (s.status === target) this.useInvoice.push(s.num)
			}
		},
		label: function (label: string) {
			if (label == 'wait') return '未処理'
			if (label == 'paid') return '入金済'
			if (label == 'sent') return '送付済'
		},
		labelColor: function (label: string) {
			if (label == 'wait') return 'gray'
			if (label == 'paid') return '#1b3dad'
			if (label == 'sent') return 'orange'
		},
		getInvoice: function () {
			this.$router.push(`/admin/invoice_print/${this.id}?numbers=${this.useInvoice.join(',')}`)
		},
		markAsPaid: async function () {},
	},
})
